import './App.css'
import { Switch, Route, useLocation } from "react-router-dom";
import First from './First'
import Second from './Second'

function App() {
  let location = useLocation();
  
  return (
    <div className="App">
      <Route render={({ location }) => (
        <Switch location={location}>
          <Route path="/" exact component={First} />
          <Route path="/second" exact component={Second} />
        </Switch>
      )} />
    </div >
  );
}

export default App;
