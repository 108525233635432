import React from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <Link to="/second">
            Към страница 2
        </Link>
    )
}

export default First