import React from 'react'
import { Link } from 'react-router-dom'

function Second() {
    return (
        <Link to="/">
            Към страница 1
        </Link>
    )
}

export default Second